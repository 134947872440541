@import "~bootstrap/dist/css/bootstrap.css";
@import "~cookieconsent/build/cookieconsent.min.css";

body {}

code {}

/* Controlar CSS do container principal */

.page-section {
  margin-top: 7px;
}

/* Mostrar bold no react-quill */
.ql-editor strong {
  font-weight: bold;
}

.ql-editor>* {
  color: black;
}

.ql-editor {
  min-height: 150px;
}